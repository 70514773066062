export const rootUrl = '/';

export const demoRoute = 'demo';
export const helpRoute = 'help';
export const loginRoute = 'login';
export const startRoute = 'start';
export const testRoute = 'test';

export const mainRoute = 'main';
export const mainSurveyRoute = 'survey';

// TODO: Add pages:
// - Select survey page ('account')
